<template>
  <q-layout id="top" :class="`theme-${theme}`" view="hHh lpR fff">
    <q-header>
      <template
        v-for="header in layout.regions?.filter((t: any) => /^header-top/.test(t.name))"
        :key="header.id"
      >
        <q-toolbar class="h-7 min-h-min bg-black flex justify-end -gap-x-4">
          <q-btn-dropdown
            :label="t('MainLayout.header.websites')"
            flat
            dense
            round
            content-class="left-0! right-0! max-w-none! w-full flex justify-center"
          >
            <ul class="container flex justify-between items-center h-36">
              <li>
                <router-link
                  class="text-gray-700 text-xl py-3 flex items-center"
                  to="/"
                >
                  <q-icon
                    class="text-green-600"
                    name="mdi-chevron-right"
                    size="30px"
                  />
                  <span>{{ t('MainLayout.header.company') }}</span>
                </router-link>
                <div class="text-gray-400 text-lg py-3">
                  {{ t('MainLayout.header.information') }}
                </div>
              </li>
              <li>
                <a
                  class="text-gray-700 text-xl py-3 flex items-center"
                  href="https://salaxy.s-iis.com/"
                  target="_blank"
                >
                  <span>{{ t('MainLayout.header.salaxy') }}</span>
                  <q-icon
                    class="text-gray-500"
                    name="mdi-chevron-right"
                    size="30px"
                  />
                </a>
                <div class="text-gray-400 text-lg py-3">
                  {{ t('MainLayout.header.salaxyProduct') }}
                </div>
              </li>
              <li>
                <a
                  class="text-gray-700 text-xl py-3 flex items-center"
                  href="https://www.s-oceanmind.com/"
                  target="_blank"
                >
                  <span>{{ t('MainLayout.header.oceanmind') }}</span>
                  <q-icon
                    class="text-gray-500"
                    name="mdi-chevron-right"
                    size="30px"
                  />
                </a>
                <div class="text-gray-400 text-lg py-3">
                  {{ t('MainLayout.header.oceanmindProduct') }}
                </div>
              </li>
              <li>
                <a
                  class="text-gray-700 text-xl py-3 flex items-center"
                  href="https://recruit.sinovatio.com"
                  target="_blank"
                >
                  <span>{{ t('MainLayout.header.joinsec') }}</span>
                  <q-icon
                    class="text-gray-500"
                    name="mdi-chevron-right"
                    size="30px"
                  />
                </a>
                <div class="text-gray-400 text-lg py-3">
                  {{ t('MainLayout.header.recruit') }}
                </div>
              </li>
            </ul>
          </q-btn-dropdown>
          <ChangeLanguage />
          <div>
            <q-icon name="mdi-phone" />
            <a
              class="px-1 text-sm"
              :href="`tel:${
                settings[t('MainLayout.header.hotline')] ?? '400-100-8102'
              }`"
              >{{
                settings[t('MainLayout.header.hotline')] ?? '400-100-8102'
              }}</a
            >
          </div>
        </q-toolbar>
      </template>
      <q-toolbar class="h-16 min-h-min flex justify-between">
        <div class="w-full h-full flex justify-between">
          <template
            v-for="header in layout.regions?.filter((t: any) => /^header-(?:left|center|right)$/.test(t.name))"
            :key="header.id"
          >
            <div class="px-10 flex justify-between items-center">
              <template v-for="region in header.children" :key="region.id">
                <a v-if="region.name === 'logo'" href="/">
                  <img class="h-14" :src="region.image" />
                </a>
                <div
                  v-if="region.name === 'toggle-icon'"
                  class="h-full w-10 px-2 flex items-center"
                  @click="toggleMenus"
                >
                  <img class="cursor-pointer" :src="region.icon" />
                </div>
                <div
                  v-if="region.name === 'toggle-menus'"
                  class="flex overflow-hidden"
                  @mouseleave="onMouseLeaveMenu"
                >
                  <template v-for="page in menus" :key="page.id">
                    <q-btn
                      v-if="page.children?.length"
                      class="h-16 lg:px-4 xl:px-6 text-sm text-primary hover:text-highlight"
                      :label="page.title"
                      flat
                      no-caps
                      @mouseenter="
                        () => {
                          state.page = page;
                          state.menuOver = true;
                        }
                      "
                      @mouseover="
                        () => {
                          state.page = page;
                          state.menuOver = true;
                        }
                      "
                      @mouseout="
                        () => {
                          state.page = page;
                          state.menuOver = false;
                        }
                      "
                      @click="
                        (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      "
                    >
                    </q-btn>
                    <q-btn
                      v-else
                      class="h-16 lg:px-4 xl:px-6 text-sm text-primary hover:text-highlight"
                      :to="tmpl(page.url!, page)"
                      :label="page.title"
                      flat
                      no-caps
                    ></q-btn>
                  </template>
                </div>

                <q-btn
                  v-if="region.name === 'search'"
                  class="text-gray-800 hover:text-green-600"
                  to="/news/search"
                  icon="mdi-magnify"
                  flat
                  round
                />
              </template>
            </div>
          </template>
        </div>

        <q-popup-proxy
          class="left-0! right-0! max-w-none! w-full flex justify-center rounded-none animate__animated animate__faster"
          v-model="state.visible"
          no-parent-event
        >
          <div
            class="w-full !max-w-full flex justify-center"
            @mouseover="() => (state.listOver = true)"
            @mouseout="() => (state.listOver = false)"
          >
            <sv-product-menu
              v-if="state.page.title === t('MainLayout.header.product')"
              :pages="state.page.children"
            />
            <div
              v-else
              class="container py-8 flex justify-center -gap-x-24 text-secondary"
            >
              <div class="hidden"></div>
              <template v-for="child in state.page.children" :key="child.id">
                <app-link
                  class="hover:text-highlight text-sm"
                  :to="tmpl(child.url!, child)"
                  >{{ child.title }}</app-link
                >
              </template>
              <div class="hidden"></div>
            </div>
          </div>
        </q-popup-proxy>
      </q-toolbar>
    </q-header>

    <q-page-container>
      <router-view />
      <div
        ref="maskRef"
        :class="`mask fixed top-0 bottom-0 left-0 right-0 hidden justify-center items-center h-dvh`"
      >
        <q-spinner-dots class="text-4xl" color="loading-color" />
      </div>

      <q-dialog v-model="state.dialogVisible" class="overflow-hidden">
        <q-card class="contact-dialog">
          <q-separator />
          <q-card-section class="scroll">
            <ContactPopupPage />
          </q-card-section>
        </q-card>
      </q-dialog>
    </q-page-container>

    <SinovatioFooter
      v-if="
        (layout.regions?.filter((t) => /footer/.test(t.name))?.length ?? 0) ===
        0
      "
      :layout="layout"
      :menus="menus"
      :settings="settings"
    />
    <q-footer v-else class="flex justify-center py-8">
      <div class="container grid grid-cols-12">
        <q-separator class="col-span-12 mb-8" :dark="dark" />
        <div class="col-span-6">
          <div class="h-full flex flex-col justify-center gap-4">
            <template
              v-for="footer in layout.regions?.filter((t: any) => t.name === 'footer-left')"
              :key="footer.id"
            >
              <div
                v-if="footer.extraProperties.theme === '竖向'"
                class="flex flex-col items-start gap-4"
              >
                <div v-if="footer.summary" class="text-primary text-xl">
                  {{ footer.summary }}
                </div>
                <div class="flex flex-col items-start text-sm">
                  <template v-for="region in footer.children" :key="region.id">
                    <img v-if="region.image" :src="region.image" />
                    <div
                      v-if="region.summary"
                      class="text-secondary"
                      v-html="region.summary"
                    ></div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="col-span-6">
          <div class="h-full flex flex-col justify-center items-end gap-4">
            <template
              v-for="footer in layout.regions?.filter((t: any) => t.name === 'footer-right')"
              :key="footer.id"
            >
              <div
                v-if="footer.extraProperties.theme === '竖向'"
                class="flex flex-col items-start gap-4"
              >
                <div v-if="footer.summary" class="text-primary text-xl">
                  {{ footer.summary }}
                </div>
                <div class="flex flex-col items-start text-sm">
                  <template v-for="region in footer.children" :key="region.id">
                    <img v-if="region.image" :src="region.image" />
                    <div
                      v-if="region.summary"
                      class="text-secondary"
                      v-html="region.summary"
                    ></div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </q-footer>

    <div
      v-if="shortcutEnabled"
      class="fixed right-10 bottom-36 flex flex-col -gap-y-2"
    >
      <q-btn-group
        class="shortcuts flex flex-col items-center bg-white"
        rounded
      >
        <q-btn icon="mdi-phone" fab-mini>
          <q-tooltip anchor="center left" self="center right">
            <div>{{ $t('MainLayout.suspend.hotline') }}</div>
            <div>
              {{
                settings[$t('MainLayout.suspend.hotline')] ??
                $t('MainLayout.header.phone')
              }}
            </div>
          </q-tooltip>
        </q-btn>
        <q-separator class="w-7" />
        <q-btn
          icon="mdi-comment-processing-outline"
          fab-mini
          @click="state.dialogVisible = true"
        >
          <q-tooltip anchor="center left" self="center right">
            {{ $t('MainLayout.suspend.online') }}
          </q-tooltip>
        </q-btn>
      </q-btn-group>
      <q-btn-group class="shortcuts flex flex-col bg-white" rounded>
        <q-btn icon="mdi-arrow-up-bold" fab-mini v-scroll-to="'#top'">
          <q-tooltip anchor="center left" self="center right">
            {{ $t('MainLayout.suspend.top') }}
          </q-tooltip>
        </q-btn>
      </q-btn-group>
    </div>
  </q-layout>
</template>

<script lang="ts">
import { useLayoutStore } from 'stores/modules/layout';
import { usePageStore } from 'stores/modules/page';
import { useMetaStore } from 'stores/modules/meta';
import 'animate.css';

const shortcutEnabled = process.env.SHORTCUTS_ENABLED === 'true';
const theme = process.env.THEME;
const dark = theme === 'dark';

export default {
  async preFetch({ store }) {
    const metaStore = useMetaStore(store);
    await metaStore.fetchAll();

    const layoutStore = useLayoutStore(store);
    await layoutStore.fetch({ url: '^$' });

    const pageStore = usePageStore(store);
    await pageStore.fetchAll();
  },
};
</script>

<script setup lang="ts">
import { reactive, ref, watch } from 'vue';
import { useMeta, debounce } from 'quasar';
import ChangeLanguage from 'components/ChangeLanguage.vue';
// import SvBtnDropdown from 'components/SvBtnDropdown.vue';
import SvProductMenu from 'components/SvProductMenu.vue';
import tmpl from 'src/utils/tmpl';
import { useI18n } from 'vue-i18n';
import ContactPopupPage from 'components/ContactPopupPage.vue';
import AppLink from 'src/components/AppLink.vue';
import SinovatioFooter from './SinovatioFooter.vue';

const { t } = useI18n();
const state = reactive<{
  page?: any;
  menusOver: boolean;
  menuOver: boolean;
  listOver: boolean;
  visible: boolean;
  dialogVisible: boolean;
}>({
  menusOver: false,
  menuOver: false,
  listOver: false,
  visible: false,
  dialogVisible: false,
});

const collapseRef = ref<HTMLElement[]>();
const hiddenMenus = debounce(() => {
  state.visible = state.menuOver || state.listOver;

  if (!state.visible && !state.menusOver) {
    for (const target of collapseRef.value ?? []) {
      if (target) {
        for (let i = 0; i < target.children.length; i++) {
          const el = target.children[i];
          el?.classList.remove('animate__fadeInUp');
          el?.classList.add('animate__fadeOutDown');
        }
      }
    }
  }
});

watch(
  () => [state.menuOver, state.listOver, state.menusOver],
  () => {
    hiddenMenus();
  }
);

const layoutStore = useLayoutStore();
const layout = layoutStore.layout;

const metaStore = useMetaStore();
useMeta(metaStore.meta);
const settings = metaStore.settings;

const pageStore = usePageStore();
const menus = pageStore.menus;

const toggleMenus = debounce((e: Event) => {
  // (e.target as HTMLElement).onmouseleave = onMouseLeaveMenu;
  state.menusOver = true;

  for (const target of collapseRef.value ?? []) {
    if (target) {
      for (let i = 0; i < target.children.length; i++) {
        const el = target.children[i];
        el?.classList.remove('animate__fadeOutDown', 'collapse');
        el?.classList.add('animate__fadeInUp');
      }
    }
  }
});

const onMouseLeaveMenu = debounce((e: Event) => {
  state.menusOver = false;
});

const maskRef = ref<HTMLElement>();
watch(
  () => pageStore.loading,
  (newValue) => {
    const el = maskRef.value;
    if (newValue) {
      el?.classList.remove('hidden');
      el?.classList.add('flex');
    } else {
      el?.classList.add('animate__animated', 'animate__fadeOut');
      el?.addEventListener(
        'animationend',
        (e: Event) => {
          e.stopPropagation();
          el?.classList.remove('flex', 'animate__animated', 'animate__fadeOut');
          el?.classList.add('hidden');
        },
        { once: true }
      );
    }
  }
);
</script>

<style>
.left-0\! {
  left: 0 !important;
}

.right-0\! {
  right: 0 !important;
}

.max-w-none\! {
  max-width: none !important;
}

.no-dropdown-icon .q-icon.q-btn-dropdown__arrow {
  display: none;
}

.q-header,
.q-page-container,
.q-footer {
  background-color: var(--bg-color);
}

.q-header {
  opacity: 0.9;
}

.q-banner__avatar {
  justify-content: center;
}

.contact-container {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.contact-dialog {
  position: absolute !important;
  right: 6rem;
}

.text-loading-color {
  color: var(--loading-color);
}

.mask {
  background-color: var(--bg-color);
}

.h-dvh {
  height: 100dvh;
}

.shortcuts {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  z-index: 9999;
}

.shortcuts.q-btn-group > .q-btn-item:not(:last-child) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.shortcuts.q-btn-group > .q-btn-item:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
</style>
