<template>
  <q-page class="flex justify-center">
    <div class="w-full flex justify-center">
      <div class="container" style="max-width: 80rem">
        <div class="bg-white">
          <div class="pl-1 pr-1">
            <div class="text-3xl font-bold text-green-500">
              {{ $t('Contact.title') }}
            </div>
            <div class="text-green-500">{{ $t('Contact.message') }}</div>
            <q-form
              ref="formRef"
              class="py-8 q-gutter-sm"
              @submit="handleSubmit"
              @reset="handleReset"
            >
              <q-input
                v-model="model.name"
                :label="$t('Contact.form.name')"
                :rules="[(val) => val && val.length > 0]"
                outlined
              />
              <q-input
                v-model="model.company"
                :label="$t('Contact.form.company')"
                :rules="[(val) => val && val.length > 0]"
                outlined
              />
              <q-input
                v-model="model.job"
                :label="$t('Contact.form.job')"
                :rules="[(val) => val && val.length > 0]"
                outlined
              />
              <div class="flex justify-between items-center -gap-x-2">
                <q-input
                  class="flex-1"
                  v-model="model.phone"
                  type="tel"
                  :label="$t('Contact.form.phone.lable')"
                  :rules="[
                    (val) =>
                      (val &&
                        val.length > 0 &&
                        /^1[3456789]\d{9}$/.test(val)) ||
                      $t('Contact.form.phone.rules'),
                  ]"
                  outlined
                />
                <q-input
                  class="flex-none w-32"
                  v-model="model.securityCode"
                  :label="$t('Contact.form.securityCode.lable')"
                  :rules="[
                    (val) =>
                      (val && val.length > 0 && /^\d{4}$/.test(val)) ||
                      $t('Contact.form.securityCode.rules'),
                  ]"
                  outlined
                />
                <q-btn
                  class="mb-6 h-9 flex-none text-green-500 text-base"
                  padding="8px"
                  flat
                  :disable="state.vertifying !== $t('Contact.form.vertifying')"
                  @click="handleSendSecurityCode"
                  >{{ state.vertifying }}</q-btn
                >
              </div>
              <div class="font-bold">{{ $t('Contact.form.about') }}</div>
              <q-btn-toggle
                v-model="model.about"
                :options="productOptions"
                text-color="grey-10"
                color="blue-grey-1"
                toggle-color="green-2"
                toggle-text-color="green-6"
                class="overflow-hidden text-overflow-ellipsis"
                flat
              />
              <q-btn
                class="w-full mt-4 text-base text-white horizontal-gradient"
                type="submit"
                flat
                :loading="state.loading"
                >{{ $t('Contact.form.submit') }}</q-btn
              >
            </q-form>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script lang="ts">
import { PageWithChildrenDto, usePageStore } from 'stores/modules/page';

export default {
  async preFetch({ store, currentRoute }) {
    const pageStore = usePageStore(store);
    await pageStore.fetch({ url: currentRoute.path });
  },
};
</script>

<script setup lang="ts">
import { ref, reactive, computed, onBeforeMount } from 'vue';
import { useMeta } from 'quasar';
import { treeToList } from 'src/utils/tree';
import { create } from 'src/api/Message';
import { isValid, sendSecurityCode } from 'src/utils/sms';
import { PageDto } from 'src/api/types';
import { Notify } from 'quasar';
import { useMetaStore } from 'stores/modules/meta';
import { useI18n } from 'vue-i18n';
import { PageWithRegionsDto } from 'src/api/types';
import { find } from 'src/api/Page';
import { filterIsActive } from 'src/utils/fetch';

const { t } = useI18n();
const pageStore = usePageStore();

const page = ref({} as PageWithRegionsDto);

const model = reactive({
  name: '',
  company: '',
  job: '',
  phone: '',
  about: '',
  securityCode: '',
});

onBeforeMount(async () => {
  const { data } = await find('/about/contact');
  filterIsActive(data);
  page.value = data;

  const id = page.value?.regions?.[0]?.extraProperties?.linkPageId;
  if (id) {
    const root = treeToList(pageStore.menus, { pid: 'parentId' }).filter(
      (t: PageWithChildrenDto) => t.id === id
    )[0];
    model.about = root.children?.[0]?.id;
  }
});

const metaStore = useMetaStore();
useMeta(
  Object.assign({}, metaStore.meta, {
    title: page.value.title,
    titleTemplate: (title: string) => `${title} - ${metaStore.meta.title}`,
  })
);

// const settings = metaStore.settings;

const formRef = ref();

const state = reactive({
  loading: false,
  vertifying: t('Contact.form.vertifying'),
});

const productOptions = computed(() => {
  const id = page.value?.regions?.[0]?.extraProperties?.linkPageId;
  if (id) {
    const root = treeToList(pageStore.menus, { pid: 'parentId' }).filter(
      (t: PageWithChildrenDto) => t.id === id
    )[0];

    const options =
      root?.children?.map((x: PageDto) => ({
        label: x.title + t('Contact.form.product'),
        value: x.id,
      })) ?? [];

    return options;
  }
  return [];
});

let timeout: NodeJS.Timeout;

const clearVertifyingTimeout = () => {
  state.vertifying = t('Contact.form.vertifying');
  clearInterval(timeout);
};

const setVertifyingTimeout = () => {
  clearInterval(timeout);
  state.vertifying = `${t('Contact.form.vertifying')}(60s)`;
  let seconds = 59;
  timeout = setInterval(() => {
    state.vertifying = `${t('Contact.form.vertifying')}(${seconds}s)`;
    seconds--;
    if (seconds < 0) {
      clearVertifyingTimeout();
    }
  }, 1000);
};

const handleSendSecurityCode = async () => {
  if (state.vertifying !== t('Contact.form.vertifying')) return;
  if (!isValid(model.phone)) {
    Notify.create({
      message: t('Contact.form.phone.lable'),
      type: 'warning',
    });
    return;
  }
  setVertifyingTimeout();

  await sendSecurityCode(model.phone);
  model.securityCode = '';
};

const handleSubmit = async () => {
  state.loading = true;
  try {
    await create({
      ...model,
    });
    formRef.value?.reset();
    Notify.create(t('Contact.form.notify'));
    clearVertifyingTimeout();
  } finally {
    state.loading = false;
  }
};

const handleReset = () => {
  model.name = '';
  model.company = '';
  model.job = '';
  model.phone = '';
  model.about = '';
  model.securityCode = '';
};
</script>

<style scoped>
.q-btn-toggle {
  display: flex;
  flex-wrap: wrap;
}
.q-btn-toggle :deep(.q-btn) {
  font-size: 16px;
  white-space: nowrap;
}
.q-btn-toggle :deep(.text-green-6) {
  background-color: #c8e6c9;
}
.horizontal-gradient {
  background: linear-gradient(to right, #2fc725, #009de9);
}
</style>
