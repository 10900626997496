<template>
  <div class="container px-0 flex">
    <div class="flex-none tabs-separtor border-r-2">
      <q-tabs v-model="tab" vertical active-class="text-highlight">
        <template v-for="(page, i) in pages" :key="page.id">
          <q-tab
            class="flex justify-start text-sm"
            v-if="page.children?.length"
            :name="i"
            :ripple="false"
            no-caps
          >
            <div class="flex items-center -gap-x-4 px-8">
              <img
                v-if="page.icon"
                class="w-6 h-6"
                :src="page.icon"
                alt="icon"
              />
              <span>{{ page.title }}</span>
            </div>
          </q-tab>
        </template>
      </q-tabs>
    </div>
    <div class="flex-1">
      <q-tab-panels
        class="h-full"
        v-model="tab"
        animated
        swipeable
        vertical
        transition-prev="jump-up"
        transition-next="jump-up"
      >
        <template v-for="(page, i) in pages" :key="page.id">
          <q-tab-panel
            class="overflow-y-hidden p-0 pt-3 text-sm -mb-8"
            v-if="page.children?.length"
            :name="i"
          >
            <div class="h-full px-4 flex justify-start items-start">
              <template v-for="child in page.children" :key="child.id">
                <div class="mr-10 mb-10">
                  <div class="mb-4">
                    <app-link
                      v-if="child.url"
                      class="hover:text-highlight"
                      :to="tmpl(child.url, child)"
                      >{{ child.title }}</app-link
                    >
                    <span v-else>{{ child.title }}</span>
                  </div>
                  <ul class="text-secondary">
                    <template
                      v-for="grandson in child.children"
                      :key="grandson.id"
                    >
                      <li class="pb-3 flex justify-start items-center">
                        <app-link
                          v-if="grandson.url"
                          class="hover:text-highlight"
                          :to="tmpl(grandson.url, grandson)"
                          >{{ grandson.title }}</app-link
                        >
                        <span v-else>{{ grandson.title }}</span>
                        <span
                          v-if="grandson.keywords.label"
                          :class="`ml-2 px-1.5 py-0.5 text-xs tag-${grandson.keywords.type}`"
                          >{{ grandson.keywords.label }}</span
                        >
                      </li>
                    </template>
                  </ul>
                </div>
              </template>
            </div>
          </q-tab-panel>
        </template>
      </q-tab-panels>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { PageWithChildrenDto } from 'stores/modules/page';
import tmpl from 'src/utils/tmpl';
import AppLink from './AppLink.vue';

defineProps<{
  pages: PageWithChildrenDto[];
}>();

const tab = ref<number>(0);
</script>

<style scoped>
.q-tabs {
  margin-right: -2px;
}

.tabs-separtor {
  border-color: var(--border-color);
}

.tag-danger,
.tag-4 {
  color: #f66540;
  background-color: #ffe1da;
}

.tag-success,
.tag-1 {
  color: #30ab39;
  background-color: #e1f4e3;
}

.tag-default,
.tag-0 {
  color: #009de9;
  background-color: #d8f0fb;
  border: 1px solid #009de9;
  border-radius: 2px;
}

.text-highlight {
  color: var(--highlight-color) !important;
}
</style>
